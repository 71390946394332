import ContentBlock from './ContentBlock';
import Footer from './Footer';
import Header from './Header';
import HeroBlock from './HeroBlock';
import PostsBlock from './PostsBlock';
import Social from './Social';
import Layout from './Layout';
import TaxLayout from "./TaxLayout";
import Tags from "./Tags";
import SEO from "./SEO";

export {
    ContentBlock,
    Footer,
    Header,
    HeroBlock,
    PostsBlock,
    Social,
    Layout,
    TaxLayout,
    Tags,
    SEO
};

export default {
    ContentBlock,
    Footer,
    Header,
    HeroBlock,
    PostsBlock,
    Social,
    Layout,
    TaxLayout,
    Tags,
    SEO
};
