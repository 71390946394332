import React from 'react';
import _ from 'lodash';

import {Link} from '../utils';

export default class Tags extends React.Component {
    render() {
        return (
            <div className="tags">
                {_.map(_.get(this.props, 'pageContext.frontmatter.tags'), (tag) => (
                    tag &&
                    <Link key={tag} to={'/tag/' + tag.toLowerCase()} >{tag}</Link>
                ))}
            </div>
        );
    }
}