import React from 'react';

export default class NewsletterCTA extends React.Component {
    render() {
        return (
            <div id="mc_embed_signup">
                <h2>Subscribe for updates</h2>
                <p>Get new posts delivered straight to your inbox!</p>

                <form action="https://jacobmckinney.us11.list-manage.com/subscribe/post?u=d76a14549c93eef2dd9c6576f&amp;id=15a7c777e0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                        <input type="email" value="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
                        <div style={{position: 'absolute', left: -5000 + 'px'}} aria-hidden="true">
                            <input type="text" name="b_d76a14549c93eef2dd9c6576f_15a7c777e0" tabindex="-1" value="" />
                        </div>
                        <div className="clear">
                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
